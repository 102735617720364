import JoditEditor from "jodit-react";
import React, { useRef } from "react";

interface RichTextEditorProps {
  tabData: string;
  handleTabDataChange: (newContent: string) => void;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  tabData,
  handleTabDataChange,
}) => {
  const editorRef = useRef(null);

  const editorConfig = {
    buttons: [
      "source",
      "|",
      "bold",
      "italic",
      "underline",
      "|",
      "superscript",
      "subscript",
      "|",
      "ul",
      "ol",
      "|",
      "outdent",
      "indent",
      "|",
      "font",
      "fontsize",
      "brush",
      "paragraph",
      "|",
      "link",
      "|",
      "align",
      "undo",
      "redo",
      "|",
      "hr",
      "|",
      "fullsize",
    ],
    defaultFont: "Montserrat",
    style: {
      fontFamily: "Montserrat",
      fontSize: "14px",
    },
    iframeCSSLinks: [
      "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap",
    ],
    controls: {
      font: {
        defaultFont: "Montserrat",
        list: {
          Montserrat: "Montserrat",
        },
      },
    },
    clipboard: {
      insertOnlyText: true, // Example: Insert only text when pasting
    },
  };

  return (
    <JoditEditor
      ref={editorRef}
      value={tabData}
      config={editorConfig}
      onBlur={(newContent) => handleTabDataChange(newContent)}
    />
  );
};

export default RichTextEditor;
