import {
  IActivityKeys,
  IActivityTabKeys,
} from "../../../enums/ActivityKeysEnum";

const activityFormInitialData: IActivityData = {
  name: {
    isRequired: true,
    value: "",
    key: IActivityKeys.name,
    title: "Name",
    isValid: true,
    validationErrorMsg: "Name is required.",
  },
  isFreeSample: {
    isRequired: false,
    value: true,
    key: IActivityKeys.isFreeSample,
    title: "Free Sample",
    isValid: true,
  },
  type: {
    isRequired: true,
    value: null,
    key: IActivityKeys.type,
    title: "Type",
    options: [],
    isValid: true,
    validationErrorMsg: "Type is required",
  },
  subType: {
    isRequired: false,
    value: null,
    options: [],
    displayOptions: [],
    key: IActivityKeys.subType,
    title: "Sub-Type",
    isValid: true,
    validationErrorMsg: "Please enter a valid sub-type from the dropdown",
  },
  gradeBands: {
    isRequired: true,
    value: [],
    key: IActivityKeys.gradeBands,
    title: "Grade Bands",
    options: [],
    isValid: true,
    validationErrorMsg: "At least one grade band is required.",
  },
  numberOfPlayers: {
    isRequired: true,
    value: null,
    key: IActivityKeys.numberOfPlayers,
    title: "No.of Players",
    options: [],
    isValid: true,
    validationErrorMsg: "No. of Players is required.",
  },
  spaceRequirement: {
    isRequired: true,
    value: null,
    key: IActivityKeys.spaceRequirement,
    title: "Space Requirements",
    options: [],
    isValid: true,
    validationErrorMsg: "Space Requirement is required.",
  },
  standardsAlignments: {
    isRequired: false,
    value: [],
    key: IActivityKeys.standardsAlignments,
    title: "Standards Main Alignment",
    options: [],
    isValid: true,
  },
  subStandardsAlignments: {
    isRequired: false,
    value: "",
    key: IActivityKeys.subStandardsAlignments,
    title: "Sub-Standards Alignments",
    isValid: true,
    validationErrorMsg: "Enter a valid Sub-Standards alignment",
  },
  selCompetencies: {
    isRequired: false,
    value: [],
    key: IActivityKeys.selCompetencies,
    title: "SEL Competencies",
    options: [],
    isValid: true,
  },
  progressionLevel: {
    isRequired: false,
    value: null,
    key: IActivityKeys.progressionLevel,
    title: "Progression Level",
    options: [],
    isValid: true,
    validationErrorMsg:
      "Please enter a valid Progression level from the dropdown",
  },
  skillsAndConcepts: {
    isRequired: true,
    value: [],
    key: IActivityKeys.skillsAndConcepts,
    title: "Skills & Concepts",
    options: [],
    isValid: true,
    validationErrorMsg: "Skills and concepts are required.",
  },
  equipment: {
    isRequired: false,
    value: [],
    key: IActivityKeys.equipment,
    title: "Equipment",
    options: [],
    isValid: true,
  },
  inclusionGuideCrosslink: {
    isRequired: false,
    value: "",
    key: IActivityKeys.inclusionGuideCrosslink,
    title: "Inclusion Guide Crosslink",
    isValid: true,
    validationErrorMsg: "Please provide a valid link",
  },
  hasSpanishTranslation: {
    isRequired: false,
    value: false,
    key: IActivityKeys.hasSpanishTranslation,
    title: "Has Spanish Translation ?",
    isValid: true,
  },
  isEquipmentRequired: {
    isRequired: true,
    value: false,
    key: IActivityKeys.isEquipmentRequired,
    title: "Equipment Required?",
    isValid: true,
    validationErrorMsg: "Equipment required is a required field.",
  },
  videoEnglish: {
    isRequired: false,
    value: "",
    key: IActivityKeys.videoEnglish,
    title: "Video English",
    isValid: true,
    validationErrorMsg: "Vimeo id will only be numbers",
  },
  videoSpanish: {
    isRequired: false,
    value: "",
    key: IActivityKeys.videoSpanish,
    title: "Video Spanish",
    isValid: true,
    validationErrorMsg: "Vimeo id will only be numbers",
  },
  diagram: {
    isRequired: false,
    value: [],
    key: IActivityKeys.diagram,
    title: "Diagrams",
    uploadedData: [],
    isValid: true,
    isModalOpen: false,
    uploadedDataWithPreviews: [],
    isConfirmModalOpen: false,
  },
  skillsAndConceptsDetailedHTML: {
    isRequired: true,
    value: "",
    key: IActivityTabKeys.skillsAndConceptsDetailedHTML,
    title: "Skills And Concepts Detailed HTML",
    tabId: 0,
    isValid: true,
    validationErrorMsg: "Skills And Concepts Detailed HTML is required.",
  },
  organization: {
    isRequired: true,
    value: "",
    key: IActivityTabKeys.organization,
    title: "Organization",
    tabId: 1,
    isValid: true,
    validationErrorMsg: "Organization field is required.",
  },
  instructions: {
    isRequired: true,
    value: "",
    key: IActivityTabKeys.instructions,
    title: "Instructions",
    tabId: 2,
    isValid: true,
    validationErrorMsg: "Instructions are required.",
  },
  equipmentDescription: {
    isRequired: false,
    value: "",
    key: IActivityTabKeys.equipmentDescription,
    title: "Equipment Description",
    tabId: 3,
    isValid: true,
  },
  teachingSuggestions: {
    isRequired: false,
    value: "",
    key: IActivityTabKeys.teachingSuggestions,
    title: "Teaching Suggestion",
    tabId: 4,
    isValid: true,
  },
  variations: {
    isRequired: false,
    value: "",
    key: IActivityTabKeys.variations,
    title: "Variations",
    tabId: 5,
    isValid: true,
  },
  differentiation: {
    isRequired: false,
    value: "",
    key: IActivityTabKeys.differentiation,
    title: "Differentiation",
    tabId: 6,
    isValid: true,
  },
  selReinforcement: {
    isRequired: false,
    value: "",
    key: IActivityTabKeys.selReinforcement,
    title: "Sel Reinforcement",
    tabId: 7,
    isValid: true,
  },
  inclusionAdaptation: {
    isRequired: false,
    value: "",
    key: IActivityTabKeys.inclusionAdaptation,
    title: "Inclusion Adaptation",
    tabId: 8,
    isValid: true,
  },
  virtualAdaption: {
    isRequired: false,
    value: "",
    key: IActivityTabKeys.virtualAdaption,
    title: "Virtual Adaptation",
    tabId: 9,
    isValid: true,
  },
  tabData: {
    tabId: 0,
  },
};

export default activityFormInitialData;
